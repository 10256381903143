<template>
 <div>
      <section id="soluciones" class="values"  v-if="!(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Mantené la tecnología de tu negocio</p>
          </header>
          <div class="row">
            <div class="col-lg-12">
              <router-link :to="{ name: 'Hardware Software', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/it/Hardware.webp" class="img-fluid" alt="">
                <h3>Hardware y Software</h3>
                <p style="color:#2c3e50;">Soluciones integrales a medida para tu organización. Renová tu Infraestructura productiva con las mejores marcas del Mercado.</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
           <section id="soluciones" class="values"  v-if="(language.indexOf('es') === -1)">
        <div class="container" data-aos="fade-up">
          <header class="section-header">
            <h2>Soluciones a Medida</h2>
            <p>Mantené la tecnología de tu negocio</p>
          </header>
          <div class="row">
            <div class="col-lg-12">
              <router-link :to="{ name: 'Hardware SoftwareEn', params: { userId: 123 }}" v-scroll-to="'#contact'">
              <div class="box" data-aos="fade-up" data-aos-delay="200" >
                <img src="assets/img/portfolio/it/Hardware.webp" class="img-fluid" alt="">
                <h3>Hardware y Software</h3>
                <p style="color:#2c3e50;">Soluciones integrales a medida para tu organización. Renová tu Infraestructura productiva con las mejores marcas del Mercado.</p>
              </div>
              </router-link>
            </div>
          </div>
        </div>
      </section>
 </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String,
    language: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.col-lg-5 {
    flex: 0 0 100%;
    max-width: 100%;
}
.img-fluid {
    max-width: 100%;
    height: 236px;
    width: auto;
}

</style>
