<template>
    <div>
      <MainBannerHS :language = "dominioactual"/>
      <SolucionesHS :language = "dominioactual"/>
  </div>
</template>

<script>
import MainBannerHS from './MainBannerHS.vue'
import SolucionesHS from './SolucionesHS.vue'

export default {
  components: {
    MainBannerHS, SolucionesHS
  },
  data () {
    return {
      dominioactual: navigator.language
    }
  },
  created () {
    this.track()
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/solutions/hardwaresoftware' })
    }
  }
}
</script>

<style>

</style>
