<template>
<div>
  <section id="home" class="hero d-flex align-items-center" style="padding-bottom:0px;" v-if="!(language.indexOf('es') === -1)">
        <div class="container" style=" max-width: 1230px;">
      <div class="row" style="height: 100%; margin: 0;">
        <div class="col-lg-6 d-flex flex-column justify-content-center" style="padding-left:40px">
          <h1 data-aos="fade-up" style="text-align:left">Hardware & Software</h1>
          <h2 data-aos="fade-up" data-aos-delay="400" style="text-align:left">Soluciones integrales a la medida de tu organización. Renová tu Infraestructura productiva con las mejores marcas del mercado.</h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <router-link :to="{ name: 'Hardware Software', params: { userId: 123 }}" v-scroll-to="'#soluciones'" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Iniciar</span>
                <i class="bi bi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200" style="height:150hv; height: 100%; padding: 0;">
          <img src="assets/img/IT.webp" class="img-fluid" style="height:auto; width:auto;max-width: 90%;" alt="" v-if="!mobileView">
          <img src="assets/img/IT.webp" class="img-fluid" style="height:auto; width:auto;max-width: 90%;" alt="" v-if="mobileView">
        </div>
      </div>
    </div>
  </section><!-- End Hero -->
    <section id="home" class="hero d-flex align-items-center" style="padding-bottom:0px;" v-if="(language.indexOf('es') === -1)">
        <div class="container" style=" max-width: 1230px;">
      <div class="row" style="height: 100%; margin: 0;">
        <div class="col-lg-6 d-flex flex-column justify-content-center" style="padding-left:40px">
          <h1 data-aos="fade-up" style="text-align:left">Hardware & Software</h1>
          <h2 data-aos="fade-up" data-aos-delay="400" style="text-align:left">Soluciones integrales a medida para tu organización. Renová tu Infraestructura productiva con las mejores marcas del Mercado.</h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div class="text-center text-lg-start">
              <router-link :to="{ name: 'Hardware SoftwareEn', params: { userId: 123 }}" v-scroll-to="'#soluciones'" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Start</span>
                <i class="bi bi-arrow-right"></i>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200" style="height:150hv; height: 100%; padding: 0;">
          <img src="assets/img/IT.webp" class="img-fluid" style="height:auto; width:auto;max-width: 90%;" alt="" v-if="!mobileView">
          <img src="assets/img/IT.webp" class="img-fluid" style="height:auto; width:auto;max-width: 90%;" alt="" v-if="mobileView">
        </div>
      </div>
    </div>
  </section><!-- End Hero -->
</div>
</template>

<script>
export default {
  name: 'MainBanner',
  props: {
    msg: String,
    language: String
  },
  data: () => {
    return {
      mobileView: true,
      showNav: false
    }
  },
  methods: {
    handleView () {
      this.mobileView = window.innerWidth <= 990
    }
  },
  created () {
    this.handleView()
    window.addEventListener('resize', this.handleView)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
